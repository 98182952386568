<template>
  <div class="sidebar">
    <img src="../assets/logo.png" alt="" class="img touch"  v-on:click="goProject()"/>

    <div class="space" v-if="isActive('projects')"></div>

    <router-link
      :to="getPath('connectors', project)"
      v-if="project"
      class="item mt-5"
    >
      <img
        v-if="!isActive('Connectors')"
        class="icon"
        :src="require('../assets/navbar/connector.png')"
      />
      <img
        v-if="isActive('Connectors')"
        class="icon"
        :src="require('../assets/navbar active/connector.png')"
      />
      <p class="text" v-bind:class="{ active: isActive('Connectors') }">
        Connectors
      </p>
    </router-link>
    <router-link
      :to="getPath('plannerlist', project)"
      v-if="project"
      class="item mt-5"
    >
      <img
        v-if="!isActive('PlannerList')"
        class="icon"
        :src="require('../assets/navbar/planner.png')"
      />
      <img
        v-if="isActive('PlannerList')"
        class="icon"
        :src="require('../assets/navbar active/planner.png')"
      />
      <p class="text" v-bind:class="{ active: isActive('PlannerList') }">
        Planners
      </p>
    </router-link>
    
    <router-link
      :to="getPath('grouplist', project)"
      v-if="project"
      class="item mt-5"
    >
      <img
        v-if="!isActive('GroupList')"
        class="icon"
        :src="require('../assets/navbar/group.png')"
      />
      <img
        v-if="isActive('GroupList')"
        class="icon"
        :src="require('../assets/navbar active/group.png')"
      />
      <p class="text" v-bind:class="{ active: isActive('GroupList') }">
        Groups & Teams
      </p>
    </router-link>

    <router-link
      :to="getPath('usersGroupList', project)"
      v-if="project"
      class="item mt-5"
    >
      <img
        v-if="!isActive('UserGroupList')"
        class="icon"
        :src="require('../assets/navbar/group.png')"
      />
      <img
        v-if="isActive('UserGroupList')"
        class="icon"
        :src="require('../assets/navbar active/group.png')"
      />
      <p class="text" v-bind:class="{ active: isActive('UserGroupList') }">
        Users Chats 
        <!-- <img
        class="icon"
        :src="require('../assets/navbar active/new.png')"
      /> -->
      </p>
      
    </router-link>


       <router-link
      :to="getPath('plannerBackup', project)"
      v-if="project"
      class="item mt-5"
    >
      <img
        v-if="!isActive('PlannerBackup')"
        class="icon"
        :src="require('../assets/navbar/planner.png')"
      />
      <img
        v-if="isActive('PlannerBackup')"
        class="icon"
        :src="require('../assets/navbar active/planner.png')"
      />
      <p class="text" v-bind:class="{ active: isActive('PlannerBackup') }">
        Backup
      </p>
    </router-link>

    <router-link
      :to="getPath('mapping', project)"
      v-if="project"
      class="item mt-5"
    >
      <img
        v-if="!isActive('UserMapping')"
        class="icon"
        :src="require('../assets/navbar/mapping.png')"
      />
      <img
        v-if="isActive('UserMapping')"
        class="icon"
        :src="require('../assets/navbar active/mapping.png')"
      />
      <p class="text" v-bind:class="{ active: isActive('UserMapping') }">
        Mapping table
      </p>
    </router-link>

    <router-link  class="item mt-5" :to="'/license'">
      <img
        v-if="!isActive('license')"
        class="icon"
        :src="require('../assets/navbar/license.png')"
      />
      <img
        v-if="isActive('license')"
        class="icon"
        :src="require('../assets/navbar active/license.png')"
      />
      <p class="text" v-bind:class="{ active: isActive('license') }">
        Licenses
      </p>
    </router-link>   
    <!-- <router-link to="#" class="item mt-5">
      <img
        v-if="!isActive('resource')"
        class="icon"
        :src="require('../assets/navbar/resource.png')"
      />
      <img
        v-if="isActive('resource')"
        class="icon"
        :src="require('../assets/navbar active/resource.png')"
      />
      <p class="text" v-bind:class="{ active: isActive('resource') }">
        Resources
      </p>
    </router-link> -->
    <router-link to="#" class="item mt-5">
      <img
        v-if="!isActive('support')"
        class="icon"
        :src="require('../assets/navbar/support.png')"
      />
      <img
        v-if="isActive('support')"
        class="icon"
        :src="require('../assets/navbar active/support.png')"
      />
      <p class="text" v-bind:class="{ active: isActive('support') }" @click="contactSupport">Support</p>
    </router-link>
    <br />
    <router-link @click.native="logout()" to="/login" class="item mt-5">
      <img class="icon" :src="require('../assets/navbar/logout.png')" />
      <p class="text">Log out</p>
    </router-link>
     <b-modal  title="Contact Support" id="contactSupport" hide-footer>
        <contactSupport />
      </b-modal>
  </div>
  
</template>

<script>
import contactSupport from "../components/popup/contactSupport.vue";
export default {
  components: {
    contactSupport
  },
  data() {
    return {
      active: "",
      project: null,
    };
  },
  name: "navbar",
  mounted() {
    this.project = this.$store.getters.getProject;
    if (!this.project) {
      this.project = this.$route.path.split("/")[2];
    }
  },
  methods: {
    contactSupport(){
         this.$bvModal.show("contactSupport");
    },
    isActive(key) {
      return key == this.$route.name.split("/")[0];
    },
    goProject() {
      this.$router.push({ path: "/homepage" });
    },
    getPath(path, project) {
      return "/" + path + "/" + project;
    },
    logout() {
      this.$store.commit("removeToken");
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style scoped>
.sidebar {
  top: 0;
  margin: 0;
  padding: 0;
  width: 324px;
  background: #f2f6ff;
  position: fixed;
  height: 100%;
  overflow: auto;
  text-align: center;
}
.img {
  margin: auto;

  width: 203px;
  height: 60px;
  margin-top: 35px;
}
.sidebar a {
  display: block;
  text-decoration: none;
}
.icon {
  float: left;
  margin-right: 20px;
  margin-left: 50px;
  height: 32px;
  width: 32px;
}
.touch {
  cursor: pointer;
}
.text {
  padding-top: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 21px;
  /* identical to box height, or 105% */

  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: #929396;

  /* color: #2A4192; */
}
.item {
  cursor: pointer;
}
.active {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 21px;
  /* identical to box height, or 105% */

  display: flex;
  align-items: center;
  letter-spacing: 0.1px;

  color: #2a4192;
}

.space {
  padding-top: 150px;
}
</style>
